import { Icon } from "@components/Icons"
import cx from "classnames"
import React from "react"

import styles from "../style.module.less"

const Footer = ({
  likes = 0,
  liked,
  comments = 0,
  handleLikeButton,
  handleCommentsButton,
  totalComments,
  isFavorite,
}) => {
  const totalCommentsEssay =
    comments &&
    comments.reduce(
      (acc, cur) => acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
      0
    )

  const HeartIcon = () =>
    liked ? (
      <img
        className=""
        src={require("@images/icons/heart-full-color.svg")}
        width="20px"
        alt="Curtidas"
      />
    ) : (
      <img
        className=""
        src={require("@images/icons/heart-line.svg")}
        width="20px"
        alt="Curtidas"
      />
    )

  return (
    <div className={styles.footer}>
      <button className={styles.likeButton} onClick={handleLikeButton}>
        <HeartIcon />
      </button>
      <span className={styles.likeAndComment}>{likes}</span>
      <img
        className="pointer"
        src={require("@images/icons/comment-line.svg")}
        width="20px"
        onClick={handleCommentsButton}
        alt="Comentários"
      />

      <span className={styles.likeAndComment}>
        {isFavorite ? totalComments : totalCommentsEssay}
      </span>
    </div>
  )
}

export default Footer
