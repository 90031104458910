import PostComments from "@components/PostComments"
import PostModal from "@components/PostModal"
import VideoWatermarkPlayer from "@components/VideoWatermarkPlayer"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import { getWatermarkURL } from "@utils/path"
import { Col, Divider, Image } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React, { useEffect, useRef, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "../style.module.less"
import Footer from "./Footer"

const Card = ({
  item,
  theater,
  heightCol,
  setHeightCol,
  isBottom,
  isVideo,
  isFavorite,
  handleLikeUnlike,
  handleUpdate,
}) => {
  const [portrait, setPortrait] = useState(true)
  const [visible, setVisible] = useState(false)
  const [showComments, setShowComments] = useState(false)
  const [likes, setLikes] = useState(item.totalLikes)
  const [liked, setLiked] = useState(item.liked)
  const [comments, setComments] = useState(isFavorite ? null : item.comments)
  const [totalComments, setTotalComments] = useState(item.total_comments)
  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)

  const videoRef = useRef(null)

  const videoWrapperRef = useRef()

  const [screenWidth] = useResize()

  const isDesktop = useMedia({ minWidth: 1024 })

  const isMobile = useMedia({ maxWidth: 767 })

  const [containerVideoRef, { width: containerVideoWidth }] = useDimensions()

  const { essay, authSite, post } = useStores()
  const { user } = authSite
  const {
    postLike,
    postDislike,
    postComment,
    postReplyComment,
    deleteComment,
    deleteReply,
    getEssayPhotoVideoComments,
  } = essay
  const { getCloudinaryWatermarkLink } = post

  const srcImage = getWatermarkURL(item.urlPhoto, user)
  const srcVideo = getWatermarkURL(item.urlVideo, user)

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(srcImage)
    setImageWatermarkUrl(imageLink)
  }

  useEffect(() => {
    getCloudinaryImageURL()
  }, [item])

  useEffect(() => {
    if (videoWrapperRef.current !== undefined) {
      const video = videoWrapperRef.current.children[0].children[0]
      video.style.objectFit = "cover"
    }
  }, [theater])

  const handleShowModal = () => {
    if (screenWidth < 768) {
      return
    }

    setVisible(true)
  }

  const handleVideoPlay = () => {
    if (videoWrapperRef.current != undefined) {
      const video = videoWrapperRef.current.children[0].children[0]
      video.style.objectFit = "contain"
    }
  }

  useEffect(() => {
    if (comments !== null) {
      setTotalComments(
        comments.reduce(
          (acc, cur) =>
            acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
          0
        )
      )
    }
  }, [comments])

  const handleLikeButton = async (isLiked) => {
    if (isLiked) {
      const { data } = await postDislike({
        mediaId: item.id,
      })

      if (data.success) {
        setLiked(false)
        setLikes(data.likes)
        handleUpdate()
      }
    } else {
      const { data } = await postLike({
        mediaId: item.id,
      })

      if (data.success) {
        setLiked(true)
        setLikes(data.likes)
        handleUpdate()
      }
    }
    if (handleLikeUnlike) handleLikeUnlike()
  }

  const onComment = async (values) => {
    const { textComment, commentId, user } = values

    if (!commentId) {
      const { data } = await postComment({
        essayPhotoVideoId: item.id,
        textComment,
      })

      setComments((prevState) => [
        ...prevState,
        {
          id: data.details.id,
          textComment: data.details.textComment,
          commentatorId: data.details.commentatorId,
          commentatorName: user.name,
          commentatorUsername: user.username,
          commentatorNickname: user.nickname,
          commentatorPhoto: user.urlProfile || user.urlPhoto,
          replys: [],
        },
      ])
      handleUpdate()

      return
    }

    if (commentId) {
      const { data } = await postReplyComment({
        essayPhotoVideoCommentId: commentId,
        textComment,
      })

      setComments(
        _map(comments, (comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replys: [
                ...comment.replys,
                {
                  id: data.details.id,
                  textComment: data.details.textComment,
                  commentatorId: data.details.commentatorId,
                  commentatorName: user.name,
                  commentatorNickname: user.nickname,
                  commentatorUsername: user.username,
                  commentatorPhoto: user.urlProfile || user.urlPhoto,
                },
              ],
            }
          }

          return comment
        })
      )
      handleUpdate()

      return
    }
  }

  const onDeleteComment = async ({ commentId }) => {
    const { data } = await deleteComment({ commentId })
    if (data.success) {
      setComments(comments.filter((comment) => comment.id !== commentId))
      handleUpdate()
    }
  }

  const onDeleteReply = async ({ commentId, replyId }) => {
    const { data } = await deleteReply({ replyId })

    if (data.success) {
      setComments(
        _map(comments, (comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              replys: comment.replys.filter((reply) => reply.id !== replyId),
            }
          }

          return comment
        })
      )
      handleUpdate()
    }
  }

  const handleSize = (img) => {
    if (img?.currentTarget?.offsetHeight > heightCol) {
      setHeightCol(img?.currentTarget?.offsetHeight)
    }

    if (img.currentTarget.naturalWidth > img.currentTarget.naturalHeight) {
      setPortrait(false)
      return
    }

    setPortrait(true)
  }

  const handleScrollComment = () => {
    setShowComments(!showComments)
    if (isBottom) {
      setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100)
    }
  }

  let videoWidth = screenWidth

  if (!theater) {
    if (videoWidth >= 576 && videoWidth < 768) {
      videoWidth = videoWidth / 2 - 6
    }

    if (videoWidth >= 768 && videoWidth < 1024) {
      videoWidth = videoWidth / 2 - 22
    }

    if (videoWidth >= 1024 && videoWidth < 1327) {
      videoWidth = (0.95 * videoWidth) / 3 - 16
    }

    if (videoWidth >= 1327) {
      videoWidth = 1280 / 3 - 18
    }
  }

  const videoHeight = 463
  const containerVideoHeight = containerVideoWidth - 12

  if (theater && isDesktop) {
    return (
      <Col
        span={8}
        lg={6}
        className={cx(styles.marginCol, {
          squareDesktop: isDesktop,
        })}
      >
        {item.urlPhoto && (
          <div onContextMenuCapture={(e) => e.preventDefault()}>
            <Image
              className={cx("", styles.imageContainer)}
              style={{
                minHeight: heightCol,
                backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlPhoto}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              role="button"
              onLoad={handleSize}
              preview={{
                src: imageWatermarkUrl,
              }}
            />
          </div>
        )}

        {item.urlVideo && (
          <div
            ref={containerVideoRef}
            className={cx("videoTheaterMode player-wrapper relative", {
              "margin-left": theater,
            })}
            onContextMenuCapture={(e) => e.preventDefault()}
          >
            <div ref={videoWrapperRef}>
              <VideoWatermarkPlayer
                ref={videoRef}
                fluid={false}
                width={containerVideoWidth}
                height={422}
                playsInline
                src={srcVideo}
                aspectRatio="4:3"
                handleClickFullScreen={null}
                onPlay={handleVideoPlay}
              />
            </div>
          </div>
        )}
      </Col>
    )
  } else if (theater && !isDesktop) {
    return (
      <div>
        {item.urlPhoto && (
          <div
            onContextMenuCapture={(e) => e.preventDefault()}
            style={{ height: screenWidth / 4, overflow: "hidden" }}
          >
            <Image
              className={styles.imageContainer}
              style={{
                backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlPhoto}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: screenWidth / 2,
              }}
              role="button"
              onLoad={handleSize}
              preview={{
                src: imageWatermarkUrl,
              }}
            />
          </div>
        )}

        {item.urlVideo && (
          <div
            ref={containerVideoRef}
            className={cx("videoTheaterMode player-wrapper relative", {
              "margin-left": theater,
            })}
            onContextMenuCapture={(e) => e.preventDefault()}
          >
            <div ref={videoWrapperRef}>
              <VideoWatermarkPlayer
                ref={videoRef}
                fluid={false}
                width={containerVideoWidth}
                height={containerVideoHeight}
                playsInline
                src={srcVideo}
                aspectRatio="4:3"
                handleClickFullScreen={null}
                onPlay={handleVideoPlay}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      {isDesktop ? (
        <Col
          className={cx(styles.marginCol, {
            landscape: !portrait,
            [styles.feedCard]: isVideo && !isDesktop,
          })}
        >
          {item.urlPhoto && (
            <Image
              className={cx("pointer", styles.photoCard, {
                ["flex-grow-1"]: !portrait,
              })}
              style={{
                minHeight: "360px",
                backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlPhoto}")`,
                // maxWidth: "280px",
              }}
              onLoad={handleSize}
              preview={{
                src: imageWatermarkUrl,
              }}
            />
          )}

          {item.urlVideo && (
            <div
              ref={containerVideoRef}
              className={cx("videoTheaterMode player-wrapper relative mb8", {
                "margin-left": theater,
              })}
              onContextMenuCapture={(e) => e.preventDefault()}
            >
              <div ref={videoWrapperRef}>
                <VideoWatermarkPlayer
                  ref={videoRef}
                  fluid={false}
                  width={videoWidth}
                  height={videoHeight}
                  /* width={280} */
                  /*  height={420} */
                  playsInline
                  src={srcVideo}
                  aspectRatio="4:3"
                  handleClickFullScreen={null}
                  onPlay={handleVideoPlay}
                  className={"videoPlay"}
                />
              </div>
            </div>
          )}
          <Footer
            likes={likes}
            liked={liked}
            comments={comments}
            handleLikeButton={() => handleLikeButton(liked)}
            handleCommentsButton={
              isMobile ? handleScrollComment : () => handleShowModal()
            }
            totalComments={totalComments}
            isFavorite={isFavorite}
          />
        </Col>
      ) : (
        <Col
          span={24}
          styles={{ paddingBottom: 30 }}
          className={cx(styles.marginCol, {
            landscape: !portrait,
            [styles.feedCard]: isVideo && !isDesktop,
          })}
        >
          {item.urlPhoto && (
            <Image
              className={cx("pointer", styles.photoCard, {
                ["flex-grow-1"]: !portrait,
              })}
              style={{
                minHeight: "422px",
                backgroundImage: `url("${process.env.S3_IMAGES}/${item.urlPhoto}")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              onLoad={handleSize}
              preview={{
                src: imageWatermarkUrl,
              }}
            />
          )}
          {item.urlVideo && (
            <div
              ref={containerVideoRef}
              className={cx("videoTheaterMode player-wrapper relative mb8", {
                "margin-left": theater,
              })}
              onContextMenuCapture={(e) => e.preventDefault()}
            >
              <div ref={videoWrapperRef}>
                <VideoWatermarkPlayer
                  ref={videoRef}
                  fluid={false}
                  width={containerVideoWidth}
                  height={422}
                  playsInline
                  src={srcVideo}
                  aspectRatio="4:3"
                  handleClickFullScreen={null}
                  onPlay={handleVideoPlay}
                  className={"videoPlay"}
                />
              </div>
            </div>
          )}

          <Footer
            likes={likes}
            liked={liked}
            comments={comments}
            handleLikeButton={() => handleLikeButton(liked)}
            handleCommentsButton={
              isMobile ? handleScrollComment : () => handleShowModal()
            }
            totalComments={totalComments}
            isFavorite={isFavorite}
          />
        </Col>
      )}

      {visible && (
        <PostModal
          visible={visible}
          handleCloseModal={() => setVisible(false)}
          item={item}
          likes={likes}
          liked={liked}
          handleLikeButton={() => handleLikeButton(liked)}
          comments={comments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
          isEssay={true}
          isFavorite={isFavorite}
          getEssayPhotoVideoComments={getEssayPhotoVideoComments}
          totalComments={totalComments}
          setTotalComments={setTotalComments}
        />
      )}

      {showComments && isMobile && (
        <>
          <div className={styles.commentDivider}>
            <Divider style={{ margin: "0px" }} />
          </div>

          <PostComments
            handleCloseModal={() => setShowComments(false)}
            item={item}
            comments={comments}
            setComments={setComments}
            onComment={onComment}
            onDeleteComment={onDeleteComment}
            onDeleteReply={onDeleteReply}
            isEssay={true}
            isFavorite={isFavorite}
            getEssayPhotoVideoComments={getEssayPhotoVideoComments}
            setTotalComments={setTotalComments}
          />
        </>
      )}
    </>
  )
}

export default Card
