import { Icon } from "@components/Icons"
import cx from "classnames"
import React from "react"

import styles from "./style.module.less"

const TheaterSwitch = ({ handleTheaterMode, theater }) => {
  return (
    <div
      className={cx(
        "theater flex flex-row items-center justify-end w-100",
        styles.theater
      )}
      onClick={handleTheaterMode}
    >
      <Icon
        name="Theater"
        alt="Modo galeria desligado"
        className={styles.theater__icon}
        fillColor={theater ? "#ea4c89" : "#BCBCBB"}
      />
    </div>
  )
}
export default TheaterSwitch
